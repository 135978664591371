.reveal-blocks {

	.reveal-block {
		background-color: $gray;
	  width: 100%;
	  padding-top: 100%;
	  background-size: cover;
	  background-repeat: no-repeat;
	  position: relative;
	  overflow: hidden;
	  cursor: default;

	  &-link {
			position: relative;
	  	top: 0;
	  	left: 0;
	  	display: block;
			cursor: pointer;
			display: block;
			text-align: right;
			text-decoration: none;

	  	&:before {
	  		content: 'Click to scroll to form!';
	  		font-size: $size-6;
	  		color: $gray-dark;
	  		font-family: $ff-title;
	  		text-transform: uppercase;
	  		transition: .6s ease;
	  	}
	  }

	  .reveal-title {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			padding: 30px;
			color: $white;
			text-transform: uppercase;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-start;
			background-image: linear-gradient(-180deg, rgba(61,61,61,0.00) 42%, rgba(61,61,61,0.61) 100%);
			text-shadow: 0 2px 4px rgba(0,0,0,0.30);

			h3 {
				font-weight: $weight-bold;
				margin-bottom: .2rem;
				letter-spacing: 2.11px;
			}

			h4 {
				margin-bottom: 0;
				letter-spacing: 1.68px;
				font-weight: $weight-medium;
			}
		}

	  .overlay-content {
			position: absolute;
			width: 100%;
			height: 100%;
			padding: 30px;
			background: $gray;
			top: 100%;
			transition: all .3s ease;
			overflow: scroll;
			display: flex;
			flex-direction: column;

			.overlay-info {
				flex: 1;
			}

			h4 {
				text-transform: uppercase;
				font-weight: $weight-medium;
			}

			p {
				font-size: $size-5;
				line-height: 1.75em;
			}
	  }

	  &:hover, &.active {
	  	.overlay-content {
	  		top: 0;

	  		.reveal-block-link:before {
					bottom: 30px;
				}
	  	}
	  }
	}
}