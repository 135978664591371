.page-banner  {
	background: $primary-color;
	color: $white;
	text-align: center;
	padding: 30px 20px;
	font-family: $ff-title;
	font-size: 25px;
	font-weight: $weight-light;
	margin-bottom: $gap;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;

	h3, .btn {
		margin: 10px;
	}
}