body  {
	padding-top: 125px;

	@media (min-width: $tablet) {
		padding-top: 75px;
	}

	@media (min-width: $widescreen) {
		padding-top: 95px;
	}
}

.navbar-small {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 50px;
	background: $gray;
	display: flex;
	justify-content: space-between;
	padding: 10px 20px;
	align-items: center;
	z-index: 550;

	.phone {
		font-size: $size-6;
	}

	@include tablet {
		display: none;
	}
}

.navbar {
	background: $white;
	box-shadow: 0 2px 14px 0 rgba(0,0,0,0.10);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: fixed;
	z-index: 500;
	top: 50px;
	left: 0;
	width: 100%;
	height: 75px;
	transition: all .2s ease;

	@include tablet {
		top: 0;
	}

	.phone {
		font-size: $size-5;
		padding-right: 5px;
	}

	a {
		text-decoration: none;
	}

	@media (min-width: $widescreen) {
		height: 95px;
	}

	&-item {
		flex-direction: column;
		text-align: center;
		padding: 10px 20px;
		@include tablet {
			height: 100%;
		}

		@media (min-width: $widescreen) {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	.mobile-toggle {
		display: flex;
		padding-right: 15px;

		.navbar-item {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.navbar-burger {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;

			span {
				display: block;
				width: 30px;
				height: 1px;
				background: $black;

				&:first-child,
				&:last-child {
					position: absolute;
					transition: all .2s ease;
				}

				&:first-child { margin-top: -10px; }
				&:last-child { margin-top: 10px; }
			}

			&.open span {
				&:first-child,
				&:last-child {
					margin-top: 0;
					height: 2px;
				}
				&:first-child { 
					transform: rotate(45deg);
				}
				&:nth-child(2) {
					opacity: 0;
				}
				&:last-child { 
					transform: rotate(-45deg);
				}
			}
		}

		@media (min-width: $widescreen) {
			display: none;
		}
	}

	&-brand {
		a {
			display: block;
			padding: 10px 20px 5px;
			transition: all .2s ease;
			background-image: linear-gradient(-180deg, #F1F1F1 0%, #FFFFFF 100%);
			background-size: 100% 75px;
		  background-position: 0 -75px;
		  background-repeat: no-repeat;

			img {
				height:  57px;
				transition: all .2s ease;

				@media (min-width: $widescreen) {
					height: 75px;
				}
			}

			&:hover {
				background-position: 0 0;
			}
		}
	}

	&-menu {
		display: none;
		padding: 15px 0 50px;
		position: absolute;
		top: 75px;
		width: 100%;
		background: $white;
		box-shadow: 0 6px 7px 0 rgba(0,0,0,0.10);
		overflow: scroll !important;
		height: calc(100vh - 75px);

		@include widescreen {
			overflow: initial !important;
			height: auto;
		}

		ul {
			li {
				position: relative;

				.navbar-dropdown {
		    	background: $white;
		    	top: 100%;
		    	left: 0;
		    	padding-bottom: 20px;
		    	transition: all .2s ease;
		    	text-align: center;

		    	@include widescreen {
		    		position: absolute;
		    		top: 100%;
		    		opacity: 0;
			    	overflow: hidden;
			    	pointer-events: none;
			    	text-align: left;
			    	padding-bottom: 10px;
		    	}

		    	a {
		    		padding: 8px 15px;
		    		display: block;
		    		font-size: $size-6;
						letter-spacing: 1.23px;
						color: #606060;
						font-family: $ff-title;
						text-transform: uppercase;
		    		font-weight: $weight-light;
		    		transition: all .2s ease;

		    		&:hover {
		    			color: $primary-color;
		    		}
		    	}
		    }

				& > a,
				& > span {
					display: block;
					padding: 10px 25px;
					text-align: center;
					font-size: $size-5;
					letter-spacing: 1.23px;
					color: $black;
					font-family: $ff-title;
					transition: all .4s ease;
					background-image: linear-gradient(-180deg, #F1F1F1 0%, #FFFFFF 100%);
					background-size: 100% 75px;
			    background-position: 0 -75px;
			    background-repeat: no-repeat;
			    text-transform: uppercase;
			    font-weight: $weight-light;
			    cursor: pointer;
			  }

				&:hover {
					& > a,
					& > span {
						background-position: 0 0;
					}
		    	.navbar-dropdown {
		    		opacity: 1;
						pointer-events: initial;
		    	}
		    }
			}
		}

		@media (min-width: $widescreen) {
			padding: 0;
			display: flex !important;
			overflow: visible !important;
			justify-content: flex-end;
			flex-grow: 1;
			position: relative;
			box-shadow: none;
			top: 0;
			background: transparent;

			ul {
				display: flex;

				li > a,
				li > span {
					height: 75px;
					line-height: 75px;
					padding: 10px 15px;
					text-align: left;
				}
			}
		}
	}
}
