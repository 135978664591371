.btn, input[type="submit"] {
  text-shadow: none;
  color: #ffffff;
  font-family: $ff-title;
  font-weight: $weight-light;
  text-transform: uppercase;
  text-decoration: none;
  padding: 9px 20px;
  border: solid 1px $black;
  border-radius: 0;
  box-shadow: none;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  outline: none;
  color: $black;
  border-color: inherit;
  font-size: $size-6;
  display: inline-block;
  letter-spacing: 1.08px;
  height: 38px;

  &.btn-small {
    padding: 5px 10px;
    height: 30px;
  }

  &.btn-primary {
    background: $primary-color;
    border-color: $primary-color;
  }

  &.btn-white {
    color: $white;
    border-color: inherit;
  }

  &.btn-solid {
    background: $white;
    color: $black;
    border: $white;
  }

  &:hover, &:active, &:focus {
    background: rgba($black, .1);
    color: $black;
    border-color: $black;

    &.btn-primary {
      background: darken($primary-color, 5%);
      border-color: $primary-color;
    }

    &.btn-white {
      background: rgba($white, .2);
      border-color: $white;
      color: $white;
    }

    &.btn-solid {
      background: $white;
      opacity: .8;
    }
  }
}


// .btn:hover{
//   background: #ffffff;
//   color: #333333;
//   border-color: #f4f4f4;
//   -webkit-transition: all 0.2s;
//   transition: all 0.2s;
// }
// .btn:active{
//   border-color: #f4f4f4;
//   background: #f4f4f4;
//   color: #ffffff;
// }
// .btn:active{
//   box-shadow: none;
// }
// .btn-primary{
//   background: #222222;
// }
// .btn-primary:hover{
//   border-color: #444444;
//   background: transparent;
//   color: #444444;
// }
// .btn-primary:active{
//   border-color: #444444;
//   background: #444444;
//   color: #ffffff;
// }
// .btn-info{
//   background: #ad8020;
// }
// .btn-info:hover{
//   background: transparent;
//   border-color: #c3993e;
//   color: #444444;
// }
// .btn-info:active{
//   border-color: #c3993e;
//   background: #c3993e;
//   color: #ffffff;
// }
// .btn-success{
//   background: #5bb75b;
// }
// .btn-success:hover{
//   background: #ffffff;
//   border-color: #6ddb6d;
// }
// .btn-success:active{
//   border-color: #6ddb6d;
//   background: #6ddb6d;
//   color: #ffffff;
// }
// .btn-warning{
//   background: #f89406;
// }
// .btn-warning:hover{
//   background: #ffffff;
//   border-color: #ffa21f;
// }
// .btn-warning:active{
//   border-color: #ffa21f;
//   background: #ffa21f;
//   color: #ffffff;
// }
// .btn-danger{
//   background: #bd362f;
// }
// .btn-danger:hover{
//   background: #ffffff;
//   border-color: #ec453c;
// }
// .btn-danger:active{
//   border-color: #ec453c;
//   background: #ec453c;
//   color: #ffffff;
// }
// .btn-inverse{
//   background: #000000;
// }
// .btn-inverse:hover{
//   background: #ffffff;
//   border-color: #222222;
// }
// .btn-inverse:active{
//   border-color: #222222;
//   background: #222222;
//   color: #ffffff;
// }
// .btn-link{
//   font-size: 12px;
//   color: #777777;
// }
// .btn-link:hover{
//   text-decoration: none!important;
//   color: #777777;
// }
// .btn-link:active{
//   color: #777777;
// }
// .btn-mini{padding: 0 14px; line-height: 17px; font-family: 'Open Sans Condensed', Arial, sans-serif; font-size: 10px;}
// .btn-large{font-size: 16px; padding: 18px 35px;}
// .btn-primary.hover{border-color: #444444;background: transparent;color: #444444;}
// .btn-primary.active,
// .btn-primary:focus{background: #444444;color: #ffffff;}


// .btn-ads {
//   width: 212px;
//   height: 44px;
//   position: relative;
//   -webkit-perspective: 200px;
//   -moz-perspective: 200px;
//   -ms-perspective: 200px;
//   -o-perspective: 200px;
//   perspective: 200px;
// }
// .btn-ads > span {
//   top: 7px;
//   top: -14px;
//   left: 7px;
//   bottom: 7px;
//   right: 7px;
//   position: absolute;
//   -webkit-transition: -webkit-transform 0.5s;
//   transition: transform 0.5s;
//   -webkit-transform-style: preserve-3d;
//   -moz-transform-style: preserve-3d;
//   -ms-transform-style: preserve-3d;
//   -o-transform-style: preserve-3d;
//   transform-style: preserve-3d;
// }
// .btn-ads.flip > span {
//   -webkit-transform: rotateX( 180deg );
//   -moz-transform: rotateX( 180deg );
//   -ms-transform: rotateX( 180deg );
//   -o-transform: rotateX( 180deg );
//   transform: rotateX( 180deg );
// }
// .btn-ads span span {
//   background: #fdf651;
//   display: block;
//   text-decoration: none;
//   top: 0px;
//   bottom: 0;
//   left: 0px;
//   right: 0px;
//   -moz-box-sizing: border-box;
//   box-sizing: border-box;
//   position: absolute;
//   display: block;
//   -webkit-backface-visibility: hidden;
//   -moz-backface-visibility: hidden;
//   -ms-backface-visibility: hidden;
//   -o-backface-visibility: hidden;
//   backface-visibility: hidden;
// }
// .back {
//   -webkit-transform: rotateX( 180deg );
//   -moz-transform: rotateX( 180deg );
//   -ms-transform: rotateX(180deg);
//   -o-transform: rotateX( 180deg );
//   transform: rotateX( 180deg );
// }
// .btn-ads{
//   background: #fdf651;
//   display: inline-block;
//   padding: 7px;
//   border: none;
// }
// .btn-ads span span{
//   font-family: 'Open Sans Condensed', Arial, sans-serif!important;
//   font-size: 16px!important;
//   font-weight: bold;
//   text-transform: uppercase;
//   color: #222222!important;
//   text-decoration: none;
//   border: solid 1px #a7a347;
//   line-height: 19px;
//   text-align: center;
//   display: inline-block;
//   padding: 12px 0 11px;
//   letter-spacing: 0.15em;
// }