.blog-comments {
	#idc-container {
		width: 100%;
		max-width: 100%;
		padding-top: $section-space;
		margin-top: $section-space-mobile;
		border-top: solid 3px $black;

		a {
			color: $black;
			font-weight: $weight-bold;
			text-decoration: underline;
		}

		div {
			float: none;
			font-size: $size-6;

			* {
				font-weight: inherit;
			}
		}

		h3 {
			font-size: $size-3;
			font-weight: $weight-light;
			letter-spacing: 1.92px;
		}

		.idc-message {
			width: 100%;
			float: none;
		}

		.idc-c .idc-c-h-inner,
		.idc-c .idc-i {
			height: auto;
		}

		.idc-c .idc-c-h {
			background: $gray;
			padding: 5px;
			height: auto;
		}

		.idc-c .idc-i a {
			font-size: $size-6;
			font-weight: $weight-bold;
		}

		.idc-btn_s {
			text-shadow: none;
		  padding: 9px 20px !important;
		  border: solid 1px $black !important;
		  border-radius: 0;
		  box-shadow: none;
		  -webkit-transition: all 0.2s;
		  transition: all 0.2s;
		  outline: none;
		  display: inline-block;
		  height: 38px;
		  transition: all .2s ease;

		  span {
		  	background: none;
		  	float: none;
		  	width: initial;
		  	height: initial;
		  	padding: 0;
		  }

		  .idc-r {
		  	color: $black;
			  font-family: $ff-title;
			  text-transform: uppercase;
			  text-decoration: none;
			  font-size: $size-6 !important;
			  line-height: 1.5em;

			  strong {
			  	font-weight: $weight-light !important;
		  		letter-spacing: 1.08px !important;
			  }
		  }

		  &:hover {
		  	background: darken( $white, 10% );

		  	.idc-r {
			  	color: $black;
			  }
		  }
		}

		.idc-comments .idc-c.idc-reply {
			background: $gray !important;
			padding: 20px 20px;
			margin-top: 30px;

			.idc-header {
				margin: 0;
				line-height: 1em;
		    margin-bottom: 1em;
		    font-size: $size-4;
		    font-family: $ff-title;
		    font-weight: $weight-light;
			}
		}

		#IDCommentsNewThreadCover {
			padding-top: $section-space-mobile;
			border-top: solid 1px $black;
			margin-top: $section-space-mobile;
		}

		textarea,
		textarea.idc-text_noresize,
		input,
		select {
			padding: 7px 15px;
			-webkit-appearance: none;
			border: solid 1px $gray-dark;
			border-radius: 0px;
			background: $white;
			max-width: 100%;
			width: 100% !important;
			outline: none;
			box-shadow: none;
			font-family: $ff-title;
			letter-spacing: 1.08px !important;
			font-size: $size-6;
			font-weight: $weight-light;
			height: 38px;

			&:focus, &:active {
				border-color: $black;
			}
		}

		#IDSubscribeToThisWrapper,
		.idc-new_subscribe {
			display: block;
			max-width:  240px;
			position: relative;
			
			&:before {
				content: '';
				display: block;
				width: 0; 
			  height: 0; 
			  border-left: 6px solid transparent;
			  border-right: 6px solid transparent;
			  border-top: 7px solid $black;
			  position: absolute;
			  bottom: 19px;
			  right: 15px;
			  pointer-events: none;
			}

			#IDSubscribeToThis {
				cursor: pointer;
				margin-top: 5px;
			}
		}

		#IDCommentNewThreadForm2,
		#IDCommentReplyInnerDiv {
			margin: 0 -10px;

			display: flex;
			flex-wrap: wrap;
		}

		label {
			margin-bottom: 5px;
			letter-spacing: 1.08px !important;
			width: 100%;
			font-family: $ff-title;
			font-size: $size-6;
			text-transform: uppercase;
			font-weight: $weight-light;
		}

		.idc-c-m {
			padding: $section-space-mobile 0;

			.idc-columns {
				width: 100%;
			}

			.idc-column {
				padding: 0 10px;
				width: 33.3%;
			}

			.idc-text,
			label {
				width: 100%;
				font-family: $ff-title;
				font-size: $size-6;
				font-weight: $weight-light;
			}

			.idc-form-info {
				font-size: $size-6;
			}
		}

		.idc-right {
			.idc-btn_l {
			  margin-top: 24px !important;
			}
			.idc-btn_l-secondary {
				margin-top: 30px !important;
			}
		}

		.idc-btn_l {
			text-shadow: none;
		  padding: 9px 20px !important;
		  border: solid 1px $black;
		  border-radius: 0;
		  box-shadow: none;
		  -webkit-transition: all 0.2s;
		  transition: all 0.2s;
		  outline: none;
		  border-color: $black;
		  background: $black;
		  display: inline-block;
		  height: 38px;
		  transition: all .2s ease;

		  span {
		  	background: none;
		  	float: none;
		  	width: initial;
		  	height: initial;
		  	padding: 0;
		  }

		  .idc-r {
		  	color: $white;
			  font-family: $ff-title;
			  text-transform: uppercase;
			  text-decoration: none;
			  font-size: $size-6 !important;
			  line-height: 1.5em;

			  strong {
			  	font-weight: $weight-light !important;
		  		letter-spacing: 1.08px !important;
			  }
		  }

		  &:hover {
		  	background: lighten( $black, 30% );

		  	.idc-r {
			  	color: $white;
			  }
		  }
		}

	}
}