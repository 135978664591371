.appointment-banner  {
	background: $primary-color;
	color: $white;
	text-align: center;
	padding: $gap 0;
}

.testimonial-slider {
	text-align: center;
	margin-bottom: $gap;
	padding: $gap / 1.125 0px;
	border-bottom: solid 1px $black;
	position: relative;
	font-weight: $weight-light;

	&:before {
		content: '“';
		pointer-events: none;
		font-size: 85px;
		color: rgba($primary-color, .3);
		font-family: $ff-body;
		font-weight: $weight-bold;
		line-height: 0px;
		display: inline-block;
	}

	.testimony {
		max-width: 880px;

		h3, h5 {
			font-family: $ff-body;
		}

		h5 {
			font-style: italic;
			color: $black;
		}

		a {
			color: $black;
			display: inline-block;
			border-bottom: solid 1px $black;
			padding-bottom: 1px;
			transition: border-color .2s ease;
			text-decoration: none;
			font-weight: $weight-light;

			&:hover { border-color: $gray-dark; }
		}
	}

	.slick-prev, .slick-next {
		width: 30px;
		height: 30px;
		background: url('../img/arrow-left.svg');
		background-position: center;
		background-repeat: no-repeat;
		text-indent: -999px;
		overflow: hidden;
		position: absolute;
		right: 20px;
		bottom: 20px;
		z-index: 1;
		outline: none;
		transition: all .2s ease;
		border: none;

		&:hover {
			opacity: .5;
		}
	}

	.slick-next {
		background-image: url('../img/arrow-right.svg');
		right: -10px;
	}
}

.main-footer {
	background: $secondary-color;
	padding: $gap / 1.75 0 $gap / 1.125;

	.footer-content {
		text-align: center;

		> div {
			margin-bottom: 40px;
		}

		.footer-address {
			p {
				font-size: $size-6;
				line-height: $size-3;
			}
		}

		.footer-brand {
			text-align: center;
		}

		.footer-social {
			a {
				display: inline-block;
				padding: 0 5px;
				margin: 0 3px;

				&:last-child {
					margin-right: -3px;
				}
			}
		}

		@media (min-width: $tablet) {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			text-align: left;

			> div {
				flex: 1;
				margin: 0;
			}

			.footer-social {
				text-align: right;
			}
		}

	}

	.copyright {
		text-align: center;
		font-weight: $weight-light;
		margin-top: 20px;
		font-size: $size-6;
	}
}


// /*========== footer_outer ==========*/

// .footer_outer
// {
// 	width:100%;
// 	background:#363736  url(../img/footer_bg.jpg) repeat-x left top;
// }

// .footer_outer_bottom
// {
// 	width:100%;
// 	background: url(../img/footer_bottom_bg.jpg) repeat-x left bottom;
// }


// .footer
// {
// 	max-width:990px;
// 	margin:0px auto;
// }

// .footer_top
// {
// 	padding:40px 0 ;
// }

// .facebook_blog
// {
// 	width:342px;
// 	height:420px;
// }

// .footer_right
// {
// 	width:625px;
// 	float:right;
// }

// .footer_info
// {
// 	width:391px;
// }

// .footer_info_bgrepeat
// {
// 	background:url(../img/footer_info_repeat.jpg)  repeat-y left top;
// 	margin-bottom:15px;
// 	height:1%;
// }

// .footer_info_bgtop
// {
// 	background:url(../img/footer_info_top.jpg) no-repeat left top;
// }

// .footer_info_bgbottom
// {
// 	background: url(../img/footer_info_bottom.jpg) no-repeat left bottom;
// 	color:#ffffff;
// 	font-size:14px;
// 	padding:0 0 15px 0px;
// }

// .footer_info_bgbottom h3
// {
// 	color:#e985b6;
// 	font-weight:normal;
// 	font-style:italic;
// 	font-size:24px;
// 	padding:15px;
// 	margin-bottom:10px;
// 	text-shadow:0px 1px 1px #3e3e3e;
// 	background:url(../img/sep4.jpg) repeat-x  left  bottom;
// }

// .footer_info_bgbottom p
// {
// 	padding:0 15px 10px 15px;
// 	line-height:18px;
// }

// .footer_info_bgbottom span
// {
// 	display:block;
// 	padding:0 15px;
// 	text-align:right;
// }

// .footer_info_bgbottom span a
// {
// 	color:#e988b8;
// 	font-size:12px;
// 	text-decoration:none;
// 	font-style:italic;
// 	text-shadow:1px 1px 2px #111111;
// }

// .contact_blog
// {
// 	background: url(../img/emeil_bg.jpg)  no-repeat left top;
// 	line-height:49px;
// 	height:49px;
// 	font-style:italic;
// 	margin-bottom:15px;
// }

// .contact_blog span
// {
// 	width:57px;
// 	display:block;
// 	text-align:center;
// }

// .contact_blog strong
// {
// 	display:block;
// 	color:#ffffff;
// 	padding:0 0 0 20px;
// 	text-shadow:0px 1px 1px #3e3e3e;
// 	font-size:24px;
// 	line-height:49px;
// 	font-weight:normal;
	
// }

// .contact_blog em
// {
// 	display:block;
// 	color:#ffffff;
// 	padding:0 0 0 20px;
// 	text-shadow:0px 1px 1px #3e3e3e;
// 	font-size:20px;
// 	font-weight:normal;
// 	font-style:italic;
// 	line-height:49px;
// }

// .contact_blog em a
// {
// 	color:#ffffff;
// 	text-decoration:none;
// }
// .contact_blog span img
// {
// 	padding:15px;
// }

// .quick_nav_area
// {
// 	width:208px;
// 	float:right;
// }

// .quick_nav_bg
// {
// 	background:url(../img/footer_right_repeat_bg.jpg) repeat-y left top;
// 	margin-bottom:15px;
// 	height:1%;
// }

// .quick_nav_top
// {
// 	background:url(../img/footer_right_top_bg.jpg) no-repeat left top;
// }

// .quick_nav
// {
// 	background: url(../img/footer_right_bottom_bg.jpg) no-repeat left bottom;
// 	padding-bottom:15px;
// }


// .quick_nav h3
// {
// 	color:#e985b6;
// 	font-weight:normal;
// 	font-style:italic;
// 	font-size:24px;
// 	padding:15px 0px ;
// 	text-shadow:0px 1px 1px #3e3e3e;
// 	margin:0 30px 13px 15px;
// 	background:url(../img/sep4.jpg) repeat-x  left  bottom;
// }

// .quick_nav ul
// {
// 	padding:0px 15px 0px 30px;
// }

// .quick_nav ul li
// {
// 	color:#FFF;
// 	font-size:16px;
// 	display:block;
// 	padding:5px 0px;
// }
// .quick_nav ul li a
// {
// 	color:#FFF;
// 	text-decoration:none;
// }

// .quick_nav ul li a:hover, .quick_nav ul li.active a
// {
// 	font-style:italic;
// }
// .social_icon
// {
// 	padding:0 15px;
// }
// .social_icon img
// {
// 	padding:0 2px;
// }

// .footer_bottom
// {
// 	line-height:34px;
// 	color:#FFF;
// 	font-size:12px;
// 	font-family:Arial, Helvetica, sans-serif;
// }

// .footer_bottom span
// {
// 	display:block;
// 	font-style: italic;
// }

// .footer_bottom strong
// {
// 	display:block;
// }

// .footer_bottom strong a
// {
// 	color:#22778c;
// 	text-decoration: underline;
// }

// @media (max-width: 990px){ /* this is the major breakpoint on this layout */
 	
// 	/* FOOTER */
// 	.facebook_blog{ /* killing this */
// 		display: none;
// 	}
// 	.footer_info_bgrepeat{ /* fix margins */
// 		margin-bottom: 25px !important;
// 	}	
// 	.footer_right{
// 		width: 100%;
// 	}	
// 	.footer_info{
// 		width: 100%;
// 		float: left;
// 	}
// 	.footer_info_bgrepeat, .contact_blog{
// 		width: 90%;
// 		margin: 5px auto;
// 	}
// 	.quick_nav_area{
// 		width: 90%;
// 		margin: 0 auto;
// 		float: none;
// 		clear: both;		
// 	}
// 	.quick_nav_area .quick_nav_bg{
// 		width: 47%;
// 	}
// 	.quick_nav_area .quick_nav_left{
// 		float: left;
// 	}
// 	.quick_nav_area .quick_nav_right{
// 		float: right;
// 	}	
// 	/* remove bgs on this stuff to account for stretching */
// 	.quick_nav, .quick_nav_top, .contact_blog, .footer_info_bgrepeat, .footer_info_bgtop, .footer_info_bgbottom{
// 		background: #222;
// 		background-image: none;
// 	}

// 	.footer_bottom .fltleft {
// 		padding-left: 10px;
// 	}
// 	.footer_bottom .fltright{
// 		padding-right: 10px;
// 	}
	
// 	.cottage_area > span, .cottage_area > span > span{
// 		max-width: 100% !important;
// 		overflow-x: hidden !important;
// 	}
	
// }


// @media (max-width: 500px){ /* for really mobile-y stuff */

	
// 	/* FOOTER */
// 	.footer_outer_bottom{
// 		background-image: none;
// 	}
// 	.footer_bottom .fltleft, .footer_bottom .fltright{
// 		float: left;
// 		width: 100%;
// 		text-align: center;
// 		height: 24px;
// 		padding-left: 0;
// 		padding-right: 0;
// 	}
// 	.footer_bottom .fltright{
// 		padding-bottom: 10px;
// 	}
// 	.quick_nav h3 {
// 		font-size: 16px;
// 	}
// }	
