.blog-columns {
	margin-bottom: -$section-space-mobile;
	.blog-post {
		margin-bottom: $section-space-mobile;
	}
}

.blog-post, .blog-header {
	position: relative;

	.blog-image {
		background: $gray;
		background-size: cover;
		background-position: center center;
		height: 315px;
		width: 100%;
		display: block;
		position: relative;
		box-sizing: border-box;
		margin-bottom: 20px;
		background-image: url('../img/blog-default.jpg');

		&:before {
			content: '';
			width: 97%;
	    height: 94%;
	    display: block;
	    border: 2px solid $white;
	    position: absolute;
	    top: 3%;
	    left: 1.5%;
			box-sizing: border-box;
			transition: .2s ease;
		}
	}

	.blog-category {
		background: $primary-color;
		color: $white;
		display: inline-block;
		font-family: $ff-title;
		font-size: 13px;
		padding: 8px 15px;
		position: relative;
		z-index: 10;
		text-transform: uppercase;
		margin-bottom: 10px;
		font-weight: $weight-light;
		cursor: pointer;
		letter-spacing: 1.9px;
		transition: all .2s ease;
		text-decoration: none;

		&:hover {
			background: darken( $primary-color, 10% );
		}
	}

	h2 {
		font-family: $ff-serif-title;
		margin-bottom: .5rem;
		transition: all .2s ease;
	}

	.blog-link {
		display: block;
		width: 100%;
		height: 100%;
		z-index: 5;
		position: absolute;
		left: 0;
		right: 0;

		&:hover {
			+ .blog-image:before {
				width: 90%;
		    height: 83%;
		    top: 8%;
		    left: 5%;
			}

			~ h2 {
				text-decoration: underline;
			}
		}
	}
}

.blog-header {
	text-align: center;
	border-bottom: solid 1px $black;
	padding-bottom: $section-space-mobile;
	margin-bottom: $section-space-mobile;

	.page-title {
		margin-bottom: .75rem;
	}

	h6 + .blog-category {
		margin-top: .75rem;
	}

	.blog-image {
		height: 450px;
		width: 110%;
		margin: $section-space-mobile -5%;
	}
}

.pagination {
	border-top: solid 1px $black;
	margin-top: $gap;
	padding-top: $gap;
	text-align: center;

	.btn {
		margin: 0 5px;

		img {
			height: 15px;
			display: inline-block;
	    margin: 2px 0px 0px 10px;
	    float: right;
		}

		&.btn-prev img {
			float: left;
			margin: 2px 10px 0px 0px;
		}
	}
}

// @media (max-width: 990px){ /* this is the major breakpoint on this layout */


// 	.banner_outer{
// 		display: none;
// 	}
	
// 	.check_blog{
// 		width: 100%;
// 		margin: 10px auto;
// 	}
	
// 	.welcome_blog {
// 		padding: 0 20px;
// 	}	

		
// 	/* WELCOME */
// 	.cont_up_text h2 {
// 		font-size: 28px;
// 	}
// 	.cont_up_text span {
// 		padding: 0 15px 0px 15px;
// 		font-size: 18px;
// 		line-height: 24px;
// 	}
	
// 	/* PROMOS */
// 	.check_blog.fltleft p{
// 		margin: 10px;
// 		overflow-x: hidden;
// 	}
// 	.check_blog_img{
// 		border: none;
// 		height: auto;
// 	}
// 	.check_blog_img img{
// 		max-width: 90%;
// 	}
// 	.check_field{
// 		text-align: center;
// 	}
// 	.check_field iframe{
// 		max-width: 100%;
// 	}
// 	.check_field img{
// 		max-width: 90%;
// 	}


//  	/* BLOG */
//  	.post h3, .inner_main_cont h3{
// 	 	font-size: 22px;
//  	}
//  	.blog-comments{
// 		padding: 0 15px; 	
//  	}
 	

// }