html {
  font-size: $body-size;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  text-size-adjust: 100%;
}

body,
button,
input,
select,
textarea {
  font-family: $ff-body;
}

body {
  color: $black;
  font-size: $body-size;
  font-weight: $weight-normal;
  line-height: $body-line-height;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $ff-title;
  font-weight: $weight-light;
  margin-bottom: .5em;

  &.title {
    margin-bottom: $gap;
  }
}

h1, .size-1 { font-size: $size-1; }
h2, .size-2 { font-size: $size-2; }
h3, .size-3 { font-size: $size-3; }
h4, .size-4 { font-size: $size-4; }
h5, .size-5 { font-size: $size-5; }
h6, .size-6 { font-size: $size-6; }

.pull-quote {
  font-weight: $weight-light;
  font-family: $ff-title;
  font-size: 2.825rem;
}

a {
  color: $black;
  cursor: pointer;
  font-weight: $weight-bold;
  text-decoration: underline;

  strong {
    color: currentColor;
    font-weight: $weight-bold;
  }

  &:hover {
    color: $primary-color;
  }
}

span {
  font-style: inherit;
  font-weight: inherit;
}

strong {
  font-weight: $weight-bold;
}

p {
  font-weight: $weight-light;
  line-height: $size-1;
  margin-bottom: 1.5em;

  &:last-item {
    margin-bottom: 0;
  }

  + .btn {
    margin-top: $size-5;
  }
}

.content {

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: .5em;
  }

  ul {
    padding-left: 20px;

    li {
      list-style-type: square;
    }
  }

  ol {
    display: table;
    margin-left: 0;
    padding-left: 0;
    list-style: none;

    li {
      display: table-row;
      counter-increment: table-ol;

      &:before {
        content: counter(table-ol) ".";
        display: table-cell;
        padding-right: 0.4em;
        text-align: right;
      }
    }
  }

  blockquote {
    border-left: solid 3px $black;
    padding: 10px 20px;
    padding-right: 20px;
  }

  pre {
    padding: 20px;
    background: $black;
    color: $white;
  }
}

.page-title {
  text-align: center;
  margin-bottom: 1.5em;
}

div {
  &.image-border {
    position: relative;

    &:before {
      content: '';
      width: 97%;
      height: 97%;
      display: block;
      border: 2px solid $white;
      position: absolute;
      top: 1.5%;
      left: 1.5%;
      box-sizing: border-box;
      transition: .2s ease;
    }
  }
}

div.align-left {
  display: block;

  @include tablet {
    float: left;
    margin: 0 50px 15px 0;
    display: block;
    max-width: 50%;
  }
}

