.home-slider {

	.slide {
		background: $gray;
		padding: 50px;

		.slide-content {
			display: none;
		}
	}

	.slick-slide {
		padding: 10px 3px;
		display: block;

		.slide {
			background: transparent;
			padding: 0;
		}

		.slide-content {
			padding: 30px;
			height: 300px;
			background-size: cover;
			background-position: center center;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-start;
			position: relative;

			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				pointer-events: none;
				width: 100%;
				height: 100%;
				background: $black;
				opacity: .3;
			}

			h3 {
				font-size: $size-5;
				letter-spacing: 1px;
				padding-bottom: 10px;
				margin-bottom: -1px;
				border-bottom: solid 1px $white;
			}

			h2 {
				font-size: $size-1 * 1.25;
				font-family: $ff-serif-title;
				margin: 0;
				border-top: solid 1px $white;
			}

			h2, h3 {
				position: relative;
				color: $white;
				z-index: 1;
				text-transform: uppercase;

				a {
					color: $white;
					text-decoration: none;
					font-weight: $weight-light;
				}
			}

			@media (min-width: $tablet) {
				padding: 40px;
				height: 450px;

				h3 {
					font-size: $size-4;
					letter-spacing: 1.2px;
				}

				h2 {
					font-size: 3rem;
				}
			}

			@media (min-width: $desktop) {
				padding: 50px;
				height: 660px;

				h3 {
					font-size: 1.1578rem;
					letter-spacing: 1.69px;
				}

				h2 {
					font-size: 3.6rem;
				}
			}
		}
	}

	.slick-prev, .slick-next {
		width: 40px;
		height: 40px;
		background: url('../img/slide-left.svg');
		background-size: 150%;
		background-position: center center;
		text-indent: -999px;
		overflow: hidden;
		position: absolute;
		top: 30px;
		left: 0;
		z-index: 1;
		outline: none;
		transition: all .2s ease;

		@media (min-width: $tablet) {
			top: 50%;
			margin-top: -30px;
			width: 60px;
			height: 60px;
			background-size: 100%;
		}

		&:hover {
			opacity: .5;
		}
	}

	.slick-next {
		background-image: url('../img/slide-right.svg');
		left: auto;
		right: 0;
	}
}