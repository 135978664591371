.image-list {
	text-align: center;
	border-collapse: collapse;
	tr {
		display: flex;
		flex-direction: column;

		td {
			margin-bottom: 20px;
			width: 100%;
		}
	}

	img {
		width: 100%;
	}

	@include tablet {
		border-collapse: initial;

		tr {
			display: inherit;

			td {
				width: 50%;
				margin-bottom: 0;
			}
		}
	}
}
