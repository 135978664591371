// entry-content .page-title{margin-top: -4px; margin-bottom: 42px; padding-bottom: 18px; border-bottom: solid 1px #777777;}
// .required-field{font-family: Arial, sans-serif;font-size: 11px;color: #eb6447;}
// .forgot{font-family: Arial, sans-serif; font-size: 11px; color: #777777; margin: 11px 16px 0 0; text-decoration: underline;}
// .forgot:hover{text-decoration: none;}
// .note {font-size: 11px; font-style: italic; color: #999999;}
// .edit{font-size: 12px; color: #222222; text-decoration: none; background: url("../img/edit.png") no-repeat left 4px; padding-left: 16px; display: block;}
// .edit:hover{color: #777777;}
// .close{width: 9px; height: 9px; display: inline-block; background-image: url(../img/remove.png)!important; background-repeat: no-repeat; text-decoration: none; opacity: 1; float: none; text-indent: -9999px;}
// .close:hover{opacity: 0.5;}

article,
aside,
figure,
footer,
header,
hgroup,
section {
  display: block;
}

img {
  height: auto;
  max-width: 100%;
}

input[type="checkbox"],
input[type="radio"] {
  vertical-align: baseline;
}

.container, .line-grid {
  margin: 0 auto;
  position: relative;
  width: 90%;

  @include desktop {
    max-width: $desktop - 2 * $gap;
    width: $desktop - 2 * $gap;
  }

  @include widescreen {
    max-width: $widescreen - 2 * $gap;
    width: $widescreen - 2 * $gap;
  }


  @include fullhd {
    max-width: $fullhd - 2 * $gap;
    width: $fullhd - 2 * $gap;
  }
}

.container-small {
  margin: 0 auto;
  position: relative;
  width: 90%;

  @include desktop {
    max-width: $desktop - 4 * $gap;
    width: $desktop - 4 * $gap;
  }

  @include widescreen {
    max-width: $widescreen - 4 * $gap;
    width: $widescreen - 4 * $gap;
  }

  @include fullhd {
    max-width: $fullhd - 8 * $gap;
    width: $fullhd - 8 * $gap;
  }
}

section {
  padding: $section-space-mobile 0 0;
  position: relative;

  &:last-of-type, &.bottom-space {
    padding-bottom: $section-space-mobile;
  }

  @include tablet {
    padding: $section-space 0 0;

    &:last-of-type, &.bottom-space{
      padding-bottom: $section-space;
    }
  }
}


.breadcrumbs {
  padding: 10px 20px;
  background: $gray;

  a {
    font-family: $ff-title;
    color: $black;
    text-transform: uppercase;
    letter-spacing: 1.08px;
    font-size: $size-6;
    font-weight: $weight-light;

    &:hover {
      text-decoration: underline;
    }
    
    img {
      float: left;
      display: block;
      margin: 4px 13px 0px 0px;
    }
  }
}



