html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

* { box-sizing: border-box !important; }

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section { 
    display: block;
}

ul, li, ol {list-style: none;}
blockquote, q {quotes: none;}
blockquote:before, blockquote:after, q:before, q:after {content: ''; content: none;}
a {margin: 0; padding: 0; font-size: 100%; vertical-align: baseline; background: transparent;}
del {text-decoration: line-through;}
abbr[title], dfn[title] {border-bottom: 1px dotted #000; cursor: help;}

body {line-height:1;}

/* tables still need cellspacing="0" in the markup */
th {font-weight: bold; vertical-align: bottom;}
td {font-weight: normal; vertical-align: top;}
hr {display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0;}

/* Form Elements */
input, select {vertical-align: middle;}
input[type="radio"] {vertical-align: text-bottom;}
input[type="checkbox"] {vertical-align: bottom;}
.ie6 input {vertical-align: text-bottom;}
select, input, textarea {font: 99% sans-serif;}

a:hover, a:active {outline: none;}
small {font-size: 85%;}
strong, th {font-weight: bold;}
td, td img {vertical-align: top;} 

sub, sup {font-size: 75%; line-height: 0; position: relative;}
sup {top: -0.5em;}
sub {bottom: -0.25em;}

/* standardize any monospaced elements */
pre, code, kbd, samp {font-family: monospace, sans-serif;}

/* hand cursor on clickable elements */
.clickable, label, input[type=button], input[type=submit], button {cursor: pointer;}

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {margin: 0;}

/* make buttons play nice in IE */
button {width: auto; overflow: visible;}

a, img a, img, a img {border:0;	outline:0; text-decoration:none;}

/* FLOAT-elements */
.fltleft {float:left;}
.fltright {float:right;}

/* let's clear some floats */
.clearfix:before, .clearfix:after { content: "\0020"; display: block; height: 0; overflow: hidden; }  
.clearfix:after { clear: both; }  

/* only to clear floats - to be used with any class as addition */
.clear {
	clear: both;
}
/* clear float with blank <div>or <br>*/
.clrflt {
	clear:both;
	line-height:1px;
	font-size:1px;
	overflow: hidden;
}