.home-cta {
	background-color: $gray;
	width: 100%;
	padding-top: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	margin-bottom: 36px;

	.home-cta-button {
		display: block;
		background: $primary-color;
		color: $white;
		height: 72px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: $ff-title;
		font-size: $size-5;
		text-transform: uppercase;
		letter-spacing: 1.38px;
		font-weight: $weight-light;
		width: 80%;
		margin-left: 10%;
		position: absolute;
		bottom: -36px;
		text-decoration: none;

		&:before {
			content: '';
			width: 97%;
	    height: 89%;
	    display: block;
	    border: 1px solid $white;
	    position: absolute;
	    top: 5%;
	    left: 1.5%;
			box-sizing: border-box;
			transition: .2s ease;
		}
	}

	.home-cta-link {
		position: absolute;
		display: block;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}

	.home-cta-link:hover + .home-cta-button:before,
	.home-cta-button:hover:before {
		width: 94%;
	  height: 74%;
	  top: 12%;
	  left: 3%;
	}
}

.home-welcome {
	h1 {
		font-weight: $weight-normal;
		font-size: 45px;
	}
	h2 {
		font-weight: $weight-light;
		font-family: $ff-body;
		font-size: 28px;
	}

	.welcome-social {
		margin-top: 30px;
		a {
			display: inline-block;
			margin: 0px 10px;
		}
		img {
			height: 28px;
		}
	}
}

.instagram-posts {
	position: relative;

	h6 {
		text-align: center;
		margin: 0 auto;
    background: white;
    width: fit-content;
    padding: 0 10px;
    margin-bottom: 20px;

		&:before,
		&:after {
			content: '';
			width: 50%;
			height: 1px;
			top: 9px;
			display: block;
			background: $black;
			z-index: -1;
	    position: absolute;
	    left: 0;
		}

		&:after {
			right: 0;
			left: auto;
		}
	}

	.instagram-post {
		position: relative;
		display: block;
		cursor: pointer;

		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			background: rgba(0,0,0, .5);;
			opacity: 0;
			transition: all .2s ease;
			cursor: pointer;
			background-image: url('../img/instagram.svg');
			background-repeat: no-repeat;
			background-position: center center;
		}

		&:hover:before {
			opacity: 1;
		}
	}
}

