form,
.wufoo {
	* {
		box-sizing: border-box;
	}

	#header,
	#logo {
		display: none;
	}

	label.desc,
	legend.desc,
	label,
	li div label,
	li span label,
	label.choice {
		display: block;
		margin-bottom: 5px;
		letter-spacing: 1.08px !important;
		width: 100%;
		font-family: $ff-title;
		font-size: $size-6 !important;
		text-transform: uppercase;
		font-weight: $weight-light;
	}

	li.date span label {
		margin: 0;
	}

	li.date input {
		@include mobile {
			padding-left: 7px;
			padding-right: 7px;
		}
	}

	textarea,
	input,
	select,
	input.text,
	textarea.textarea,
	input.text,
	textarea.textarea,
	select.select,
	label.choice input[type="submit"] {
		padding: 7px 15px;
		-webkit-appearance: none;
		border: solid 1px $gray-dark;
		border-radius: 0px;
		background-color: #fafafa;
		max-width: 100%;
		width: 100%;
		outline: none;
		box-shadow: none;
		font-family: $ff-title;
		letter-spacing: 1.08px;
		font-size: $size-6 !important;
		font-weight: $weight-light;
		height: 38px;

		&:hover,
		&:focus,
		&:active {
			border-color: $black;
		}
	}

	textarea,
	textarea.textarea {
		height: auto;
	}

	input[type="submit"] {
		width: initial;
		cursor: pointer;
	}

	select,
	select.select {
		padding-right: 30px;
		cursor: pointer;
		background-color: $gray-light;
		background-image: url("../img/down-arrow.svg") !important;
		background-repeat: no-repeat;
		background-position: top right;
		font-size: 16px;
	}

	li .datepicker {
		margin: -2px 5px 0 0;
		padding: 0;
		width: 36px;
		height: 36px;
		background-image: url("../img/calendar.svg");
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 67%;
		padding: 20px;
		display: none;
	}

	li.focused {
		background: $secondary-color !important;
	}

	.info {
		border-bottom: none;

		h2 {
			font-size: 160%;
			font-family: inherit;
			color: #000000;
		}
		div {
			font-size: 95%;
			font-family: inherit;
			color: #444444;
		}
	}

	.section {
		padding: 0;

		h3 {
			border-top: solid 2px;
			font-size: 110%;
			font-family: inherit;
			background: #ffffff;
			color: #000000;
			margin: 40px 0 20px;
			padding: 0;
			padding-top: 20px;
		}

		div {
			font-size: $size-4;
			font-family: $ff-body;
			color: $black;
			padding: 0;
			margin-bottom: 20px;
		}
	}

	.instruct {
		border: none;
		font-size: 80%;
		font-family: inherit;
		color: #444444;
		background-color: $gray-light;
	}

	li.is-half {
		@include tablet {
			width: 49.4% !important;
			display: inline-block;
		}
	}

	li.contact {
		&-name {
			label ~ span {
				width: 48%;
				margin-right: 3%;

				&:last-child {
					margin-right: 0;
				}
			}
		}

		&-email,
		&-phone {
			display: inline-block;
			width: 49% !important;
		}

		&-date {
			span {
				width: 26%;

				&.symbol,
				&:last-child {
					width: auto;
				}
			}
		}
	}

	.feature-your-wedding-images div {
		text-align: center;
	}
}

.running {
	@include until($tablet) {
		display: flex;
		flex-direction: column;
	}

	li {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -10px;
		margin-bottom: 5px;

		@include until($tablet) {
			width: 100%;
		}

		> * {
			flex: 1;
			display: block;
			padding: 0 10px;

			&.symbol {
				flex: 0;
				padding: 0;
				margin: 0;
				margin-left: -0.3em;
			}

			input + label,
			textarea + label {
				font-family: $ff-body;
				text-transform: initial;
				padding: 0px;
				margin-top: 5px;
			}
		}

		&.total {
			@include until($tablet) {
				order: 1;
				padding: 6px 1% 9px 1%;
				height: auto;
				width: 100%;
				margin: 0;

				#lola {
					position: initial;
					width: 100%;
					margin: 0 !important;
				}
			}
		}

		p {
			flex: none;
			line-height: 1.5em;
			padding: 10px 20px 15px;
		}

		label {
			padding: 0 10px;
			display: inline-block;
			flex: none;
		}

		.req {
			display: inline-block;
			padding: 0;
		}
	}
}
