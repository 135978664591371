@import "layout/reset";
@import "layout/variables";
@import "mixins/responsive";

@import "layout/grid";
@import "layout/typography";
@import "layout/default";
@import "layout/helpers";
@import "layout/header";
@import "layout/footer";

@import "partials/button";
@import "partials/forms";
@import "partials/home-slider";
@import "partials/reveal-blocks";
@import "partials/comments";
@import "partials/page-banner";
// @import "partials/content-area";
// @import "partials/product-grid";
// @import "partials/banner-outer";
// @import "partials/carousel";
// @import "partials/pinterest";

@import "pages/home";
@import "pages/blog";
@import "pages/contact";
@import "pages/listings";
// @import "pages/default";
// @import "pages/product";
// @import "pages/shop";

.addthis-smartlayers {
	display: none!important;
}