.contact-image {
	img {
		width: 100%;
	}
}

.contact-hours {
	p  {
		margin-bottom: 0;
		font-size: 15px;
		line-height: 23px;

		strong {
			width: 130px;
			display: inline-block;
		}
	}
}



.holiday-hours {
	text-align: center;

	@include tablet {

		column-count: 2;
	}

	@include desktop {
		column-count: 3;
		text-align: left;
	}

	@include widescreen {
		column-count: 4;
	}

	p {
		margin-bottom: 0;
		font-size: 15px;
		line-height: 23px;

		strong {
			width: 110px;
			display: inline-block;
		}
	}
}