.has-text-centered {
	text-align: center;

	* {
		text-align: center;
	}
}

.has-text-right {
	text-align: right;
}

@include tablet {
	.has-text-right-tablet {
		text-align: right;
	}
}

.m-b-1 { margin-bottom: $gap !important; }

.p-t-0 { padding-top: 0 !important; }
.p-b-0 { padding-bottom: 0 !important; }
.m-t-0 { margin-top: 0 !important; }
.m-b-0 { margin-bottom: 0 !important; }

.hide, .hidden {
	display: none !important;
}

@include mobile {
  .is-hidden-mobile {
    display: none !important;
  }
}


@include tablet {
  .is-hidden-tablet {
    display: none !important;
  }
}


@include tablet-only {
  .is-hidden-tablet-only {
    display: none !important;
  }
}


@include touch {
  .is-hidden-touch {
    display: none !important;
  }
}


@include desktop {
  .is-hidden-desktop {
    display: none !important;
  }
}


@include desktop-only {
  .is-hidden-desktop-only {
    display: none !important;
  }
}


@include widescreen {
  .is-hidden-widescreen {
    display: none !important;
  }
}


@include widescreen-only {
  .is-hidden-widescreen-only {
    display: none !important;
  }
}


@include fullhd {
  .is-hidden-fullhd {
    display: none !important;
  }
}


.is-invisible {
  visibility: hidden !important;
}

@include mobile {
  .is-invisible-mobile {
    visibility: hidden !important;
  }
}


@include tablet {
  .is-invisible-tablet {
    visibility: hidden !important;
  }
}


@include tablet-only {
  .is-invisible-tablet-only {
    visibility: hidden !important;
  }
}


@include touch {
  .is-invisible-touch {
    visibility: hidden !important;
  }
}


@include desktop {
  .is-invisible-desktop {
    visibility: hidden !important;
  }
}


@include desktop-only {
  .is-invisible-desktop-only {
    visibility: hidden !important;
  }
}


@include widescreen {
  .is-invisible-widescreen {
    visibility: hidden !important;
  }
}


@include widescreen-only {
  .is-invisible-widescreen-only {
    visibility: hidden !important;
  }
}


@include fullhd {
  .is-invisible-fullhd {
    visibility: hidden !important;
  }
}
