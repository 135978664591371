//VARIABLES.SCSS

// COLORS
$black: #000000;
$white: #ffffff;

$gray: #F0F0F0;
$gray-light: #FAFAFA;
$gray-dark: #CECECE;

$primary-color: #4F9DB0;
$secondary-color: #E0F7FD;

// TYPOGRAPHY
@import url('https://fonts.googleapis.com/css?family=Oswald:300,400,700|Playfair+Display');
@import url("https://use.typekit.net/enc5nyd.css");

$ff-title: 'Oswald', sans-serif;
$ff-body: brandon-grotesque, sans-serif;
$ff-serif-title: 'Playfair Display', serif;;

$body-family: $ff-body;

$body-size: 19px;
$body-line-height: 1.25 !default;

$size-1: 1.789rem !default; // 34px
$size-2: 1.526rem !default; // 29px
$size-3: 1.3157rem !default; // 25px
$size-4: 1rem !default; // 19px
$size-5: .842rem !default; // 16px
$size-6: .7368rem !default; // 14px

$weight-light: 200 !default;
$weight-normal: 400 !default;
$weight-medium: 500 !default;
$weight-semibold: 600 !default;
$weight-bold: 700 !default;

// GRID
$column-gap: 0.75rem !default;
$wide-gap: 2rem !default;

// Responsiveness

$gap: 64px !default;

$section-space: $gap * 1.5;
$section-space-mobile: $gap / 2;

$mobile: 475px;
$tablet: 769px !default;
$desktop: 960px + 2 * $gap !default;
$widescreen: 1152px + 2 * $gap !default;
$widescreen-enabled: true !default;
$fullhd: 1344px + 2 * $gap !default;
$fullhd-enabled: true !default;